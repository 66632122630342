.addNewsContainer {
  gap: 14px;
}
.formBox {
  gap: 20px 0;
}
.formBox label {
  margin-bottom: 6px;
}
.datePickerBox {
  outline: none;
  border: none;
}
.newsContentActions {
  gap: 10px;
}
.deleteNewsContentMarkupBtn {
  background-color: rgb(255, 80, 80);
  border: none;
}
.deleteNewsContentMarkupBtn:is(:hover, :focus) {
  background-color: rgb(255, 34, 34);
}
